import { ArrowLeft, SearchX } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/shadcn/button';

function UnAuthorized() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col p-4 pr-0 pb-9 h-full items-center justify-center w-full gap-4">
      <h1 className="text-2xl font-semibold flex gap-2 items-center">
        <SearchX />
        UnAuthorized
      </h1>
      <p className="text-sm text-muted-foreground">
        You do not have sufficient permissions to access this page
      </p>
      <Button
        onClick={() => {
          navigate(-1);
        }}
        variant="default"
        className="gap-2"
      >
        <ArrowLeft className="w-4" />
        Go back
      </Button>
    </div>
  );
}

export default UnAuthorized;
