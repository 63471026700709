import { useQuery } from '@tanstack/react-query';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useSession } from '@descope/react-sdk';
import { allApi } from 'src/api';

import {
  EntityTypeSchema,
  EnumTypeSchema,
  PermissionType,
  Role,
} from 'src/generated';

type AppConfigProviderProps = {
  children: React.ReactNode;
};

type AppConfigProviderState = {
  entityTypes: EntityTypeSchema[];
  enumLabels: EnumTypeSchema[];
  userRole: Role[] | undefined;
  hasPermissions: (action: PermissionType) => boolean;
  loading: boolean;
};

const initialState: AppConfigProviderState = {
  entityTypes: [],
  enumLabels: [],
  userRole: [],
  hasPermissions: () => false,
  loading: false,
};

const AppConfigProviderContext =
  createContext<AppConfigProviderState>(initialState);

export function AppConfigProvider({
  children,
  ...props
}: AppConfigProviderProps) {
  const { isSessionLoading, isAuthenticated } = useSession();
  const { data: entityTypesResponse, isLoading: entityTypesLoading } = useQuery(
    {
      queryKey: ['app-config', 'entity-types'],
      queryFn: async () => allApi.listEntityTypeSchemas(),
      enabled: isAuthenticated && !isSessionLoading,
      retry: 5,
      retryDelay: 500,
    }
  );

  const { data: enumLabelsResponse, isLoading: enumTypesLoading } = useQuery({
    queryKey: ['app-config', 'enum-types'],
    queryFn: async () => allApi.listEnumTypeSchemas(),
    enabled: isAuthenticated && !isSessionLoading,
    retry: 5,
    retryDelay: 500,
  });

  const { data: meData, isLoading: meDataLoading } = useQuery({
    queryKey: ['app-config', 'me'],
    queryFn: async () => allApi.getOrCreateUser(),
    enabled: isAuthenticated && !isSessionLoading,
    retry: 5,
    retryDelay: 500,
  });

  const hasPermissions = useCallback(
    (action: PermissionType) => {
      const currentUserPermissions = (
        meData?.data.role?.map(
          (role) => role.permissions?.map((per) => per.name) ?? []
        ) ?? []
      )
        .flat()
        .filter((r) => !!r);
      // return false;
      return currentUserPermissions.includes(action);
    },
    [meData?.data.role]
  );

  const value = useMemo(
    () => ({
      entityTypes: entityTypesResponse?.data ?? [],
      enumLabels: enumLabelsResponse?.data ?? [],
      userRole: meData?.data.role,
      loading: entityTypesLoading || enumTypesLoading || meDataLoading,
      hasPermissions,
    }),
    [
      entityTypesResponse?.data,
      enumLabelsResponse?.data,
      meData?.data.role,
      entityTypesLoading,
      enumTypesLoading,
      meDataLoading,
      hasPermissions,
    ]
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AppConfigProviderContext.Provider {...props} value={value}>
      {children}
    </AppConfigProviderContext.Provider>
  );
}

export const useAppConfig = () => {
  const appConfig = useContext(AppConfigProviderContext);

  if (appConfig === undefined) {
    throw new Error('useAppConfig must be used within a AppConfigProvider');
  }

  return appConfig;
};
