import { memo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { PrimaryNavigation } from 'src/components/Navigations/PrimaryNavigation';
import PublicRouteNotFound from 'src/layout/PublicRouteNotFound';
import UnAuthorized from 'src/layout/UnAuthorized';
import FileNotFound from '../layout/RouteNotFound';
import { privateRoutesConfig, publicRoutesConfig } from './routes';

function AllRoutes({ isAuthenticated }: { isAuthenticated: boolean }) {
  const { pathname } = useLocation();
  const pseudoPublicPage = pathname === '/welcome';

  if (isAuthenticated && !pseudoPublicPage)
    return (
      <div className="main-content-box">
        <PrimaryNavigation />
        <div className="flex transition-all duration-300 pb-4 pr-4 pl-6 bg-[--page] h-screen">
          <Routes>
            {privateRoutesConfig.map((route) => (
              <Route
                path={route.path}
                Component={route.component}
                key={route.path}
              />
            ))}
            <Route path="/" element={<Navigate to="/discovery/assets" />} />
            <Route path="/unAuthorized" Component={UnAuthorized} />
            <Route path="*" Component={FileNotFound} />
          </Routes>
        </div>
      </div>
    );

  return (
    <Routes>
      {publicRoutesConfig.map((route) => (
        <Route path={route.path} Component={route.component} key={route.path} />
      ))}
      <Route path="*" Component={PublicRouteNotFound} />
    </Routes>
  );
}

export default memo(AllRoutes);
