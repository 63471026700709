/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The permission enum.
 * @export
 * @enum {string}
 */

export const PermissionType = {
    ManageCollectors: 'MANAGE_COLLECTORS',
    ManageDataSources: 'MANAGE_DATA_SOURCES',
    ManageSingulrUsers: 'MANAGE_SINGULR_USERS',
    ManagePolicy: 'MANAGE_POLICY',
    ManageBrowserExtSettings: 'MANAGE_BROWSER_EXT_SETTINGS',
    ManageIntegrations: 'MANAGE_INTEGRATIONS',
    ManagePiiSettings: 'MANAGE_PII_SETTINGS',
    ManageEmailSettings: 'MANAGE_EMAIL_SETTINGS',
    ManageOnboarding: 'MANAGE_ONBOARDING',
    AddTag: 'ADD_TAG',
    ViewPiiData: 'VIEW_PII_DATA',
    ViewReadOnlyData: 'VIEW_READ_ONLY_DATA',
    ExportTableData: 'EXPORT_TABLE_DATA',
    ManageInfrastructureSettings: 'MANAGE_INFRASTRUCTURE_SETTINGS'
} as const;

export type PermissionType = typeof PermissionType[keyof typeof PermissionType];



