import { useCallback, useState } from 'react';
import { DialogTitle } from '@radix-ui/react-dialog';
import { Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from '@/components/shadcn/dialog';

import { Button } from '@/components/shadcn/button';
import { Input } from '@/components/shadcn/input';
import Textarea from '@/components/shadcn/textarea';
import { useToast } from '@/components/shadcn/use-toast';
import { useAppConfig } from 'src/contexts/AppConfig';
import { PermissionType } from 'src/generated';

type ConnectDialogFormData = {
  useremail: string;
  jiraurl: string;
  apikey: string;
};

const messages = {
  success: {
    JIRA: 'You can use Jira Tikcet Linking now',
    SLACK: 'You can use Slack now',
  },
};

interface ConnectDialogueProps {
  isLoading: boolean;
  connecting: boolean;
  setConnection: any;
  enabled: boolean;
  type: 'JIRA' | 'SLACK';
}
function ConnectDialogue({
  setConnection,
  enabled,
  isLoading,
  connecting,
  type,
}: ConnectDialogueProps) {
  const { hasPermissions } = useAppConfig();
  const disableAction = !hasPermissions(PermissionType.ManageIntegrations);

  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<ConnectDialogFormData>({
    mode: 'onBlur',
  });

  const { toast } = useToast();

  const handleConnect = useCallback(
    async (values: ConnectDialogFormData) => {
      try {
        await setConnection({
          url: values.jiraurl,
          user: values.useremail,
          api_key: values.apikey,
        });
        reset();
        toast({
          variant: 'success',
          title: 'Integration done Successfully!',
          description: messages.success[type as 'JIRA' | 'SLACK'],
        });
      } catch (e: any) {
        toast({
          variant: 'destructive',
          title: 'We could not perform the integration',
          description: 'Something went wrong! We are looking at it!',
        });
        console.error(e);
      }
    },
    [setConnection, toast, reset]
  );

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
    if (!isOpen) {
      reset();
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          disabled={!enabled || isLoading || disableAction}
          className="border border-indigo-500"
        >
          {isLoading ? (
            <div className="w-16 h-4 bg-slate-900 animate-pulse" />
          ) : enabled ? (
            'Connect'
          ) : (
            'Coming soon'
          )}
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[600px]">
        <DialogHeader>
          <DialogTitle>Integrate JIRA</DialogTitle>
          <DialogDescription>Integrate jira to the service</DialogDescription>
        </DialogHeader>
        <form
          className="w-full mt-4 flex flex-col gap-5"
          onSubmit={handleSubmit(handleConnect)}
        >
          <div className="flex flex-col gap-1">
            <label className="text-sm">User Email</label>
            <Input
              {...register('useremail', {
                required: 'User email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Enter a valid email address',
                },
              })}
              placeholder="Enter email id here"
              className={errors.useremail ? ' border-red-400' : ''}
            />
            {errors.useremail ? (
              <p className="text-sm text-red-500">
                {errors.useremail.message?.toString()}
              </p>
            ) : null}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">Jira Url</label>
            <Input
              {...register('jiraurl', {
                required: 'Jira URL is required',
                pattern: {
                  value: /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/,
                  message: 'Enter a valid URL',
                },
              })}
              placeholder="Enter jira url here"
              className={errors.jiraurl ? ' border-red-400' : ''}
            />
            {errors.jiraurl && (
              <p className="text-sm text-red-500">
                {errors.jiraurl.message?.toString()}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">API Key</label>
            <Textarea
              {...register('apikey', {
                required: 'API key is required',
              })}
              maxLength={1000}
              placeholder="Enter API key here"
              className={errors.apikey ? ' border-red-400' : ''}
            />
            {errors.apikey && (
              <p className="text-sm text-red-500">
                {errors.apikey.message?.toString()}
              </p>
            )}
          </div>
          <DialogFooter className="flex gap-3 justify-end">
            <DialogClose asChild>
              <Button variant="ghost" className="border">
                Cancel
              </Button>
            </DialogClose>
            <Button
              type="submit"
              disabled={!isValid || connecting || disableAction}
              className="gap-2"
            >
              {connecting ? <Loader2 className="w-4 h-4 animate-spin" /> : null}
              Connect
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ConnectDialogue;
