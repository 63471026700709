/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The permission enum.
 * @export
 * @enum {string}
 */

export const RoleType = {
    Administrator: 'ADMINISTRATOR',
    ReadOnly: 'READ_ONLY'
} as const;

export type RoleType = typeof RoleType[keyof typeof RoleType];



