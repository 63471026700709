import { PropsWithChildren, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppConfig } from 'src/contexts/AppConfig';
import { PermissionType, RoleType } from 'src/generated';

interface RBACProps extends PropsWithChildren {
  allowedRole?: RoleType;
  allowedPermission?: PermissionType;
  fallback?: ReactNode;
}

export function RBAC({
  allowedRole,
  children,
  allowedPermission,
  fallback = null,
}: RBACProps) {
  const { userRole } = useAppConfig();

  const currentUserRoles = (userRole?.map((role) => role.name) ?? []).filter(
    (r) => !!r
  );
  const currentUserRolePermissions =
    userRole
      ?.map(
        (role) =>
          role.permissions
            ?.map((permission) => permission.name)
            .filter((permission) => !!permission)
            .flat() ?? []
      )
      .flat() ?? [];

  if (!allowedPermission && !allowedRole) {
    throw new Error(
      'One of Permission or role is required with Role Based Access'
    );
  }
  if (
    (allowedRole && currentUserRoles.includes(allowedRole)) ||
    (allowedPermission &&
      currentUserRolePermissions?.includes(allowedPermission))
  ) {
    return children;
  }

  return fallback;
}

interface RBACROUTEProps extends PropsWithChildren {
  allow: RoleType;
  fallback?: () => void;
}

export function RBACROUTE({ allow, children, fallback }: RBACROUTEProps) {
  const { userRole, loading } = useAppConfig();
  const currentUserRoles = (userRole?.map((role) => role.name) ?? []).filter(
    (r) => !!r
  );
  if (loading) {
    return (
      <div className=" flex-1 h-screen bg-background">
        <div className="h-full w-ful flex items-center justify-center">
          <div className="sui-loader" />
        </div>
      </div>
    );
  }

  if (currentUserRoles.includes(allow)) {
    return children;
  }
  if (fallback) {
    fallback();
  } else {
    return <Navigate replace to="/unAuthorized" />;
  }
}
